<template>
  <div class="onLoading" v-if="show">
    <div class="lmask">
      <i class="el-icon-loading" style="font-size: 40px;"></i>
      <div>正在加载</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.onLoading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  .lmask {
    background: rgba(0, 0, 0, 0.35);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    div {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}
</style>
